import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Modal,
  Box,
  MenuItem,
  CircularProgress,
  Alert,
  Snackbar,
  Card,
  CardContent,
  Typography,
  Divider,
  Autocomplete,
  Select,
  Paper,
  List,
  ListItem,
} from "@mui/material";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../../Store/AppContext";
import RTGSPayment from "./RTGSPayment";
import { Clear } from "@mui/icons-material";
import { FormatDecimal, parseNumber } from "../../../utils/formatNumber";
import { NumericFormat } from "react-number-format";
import { RecommendationLogic } from "./RecommendationLogic";
import {
  ProductCode,
  BankName,
  PaymentMode,
  isholiday,
  Name_Days,
  FundData,
  Data_Kfintech_AMC,
} from "../../../Data/data";
import { idText } from "typescript";

import { ToWords } from "to-words";

// Initialize the `ToWords` instance with the Indian numbering system

const CreateMultipleOrdersNSE = ({ accessToken }) => {
  const [storeState, dispatch] = useAppContext();
  const [open, setOpen] = React.useState(false);
  const [OTP, setOTP] = useState("");
  const [modelName, setModelName] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [TransactionCount, setTransactionCount] = useState<any>([]);
  const [msg, setMsg] = React.useState("");
  const [userBank, setUserBank] = useState(storeState.ACTIVEINVETOR?.bank_list);
  const [selectedFund, setSelectedFund] = useState("");

  const [scheme, setScheme] = useState("");
  const [Schemes, setSchemes] = useState<any>([]);
  const [searchSchemes, setSearchSchemes] = useState<any>([]);
  const [selectedSchemes, setSelectedSchemes] = useState<any>([]);
  const [isRecommended, setIsRecommended] = useState<boolean>(true);

  const [activeRow, setActiveRow] = useState(null);
  const [amountInWord, setAmountInWord] = useState<string | null>(null);

  const BankNotOnlinepayment = ["CSBF", "BNPP", "SBOM"];

  const dateConverter = (str) => {
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date = new Date(str);
    var mnth = date.getMonth();
    var day = ("0" + date.getDate()).slice(-2);
    var year = date.getFullYear();
    return `${day}-${month[mnth]}-${year}`;
  };

  const TimeHorizon = ["0 - 3 Months", "3 - 12 Months", "More than 1 year"];

  const [formData, setFormData] = useState({
    iin: storeState?.ACTIVEINVETOR?.folio.Folio,
    sub_trxn_type: "N",
    poa: "N",
    poa_bank_trxn_type: "",
    trxn_acceptance: "", //ALL for all
    demat_user: "N",
    dp_id: "",
    bank: storeState?.ACTIVEINVETOR?.bank_list[0]?.BANK_CODE,
    ac_no: storeState?.ACTIVEINVETOR?.bank_list[0]?.ACNUM,
    ifsc_code: storeState?.ACTIVEINVETOR?.bank_list[0]?.IFSC,
    sub_broker_arn_code: "",
    sub_broker_code: "",
    euin_opted: "Y",
    trxn_execution: "",
    remarks: "",
    payment_mode: "",
    billdesk_bank: storeState?.ACTIVEINVETOR?.bank_list[0]?.BANK_CODE,
    instrm_bank: "HDF",
    instrm_ac_no: "",
    instrm_no: "",
    instrm_date: "",
    instrm_branch: "",
    instrm_charges: "",
    micr: "",
    rtgs_code: storeState?.ACTIVEINVETOR?.bank_list[0]?.IFSC,
    neft_ifsc: "",
    advisory_charge: "",
    dd_charge: "",
    cheque_deposit_mode: "",
    debit_amount_type: "",
    sip_micr_no: "",
    sip_bank: "",
    sip_branch: "",
    sip_acc_no: "",
    sip_ac_type: "",
    sip_ifsc_code: "",
    sip_paymech: "",
    umrn: storeState?.ACTIVEINVETOR?.folio.umrn
      ? storeState?.ACTIVEINVETOR?.folio?.umrn
      : "", //auot demante
    ach_amt: "",
    ach_fromdate: "",
    ach_enddate: "",
    until_cancelled: "",
    Return_paymnt_flag: "N",
    Client_callback_url: "",
    Bank_holder_name: "",
    Bank_holder_name1: "",
    Bank_holder_name2: "",
    trxn_initiator: "",
    trans_count: "",
    utr_no: "",
    transfer_date: "",
    investor_auth_log: "",
    ach_exist: "",
    process_mode: "",
    channel_type: "",
    folio: "",
    product_code: "",
    amc: "",
    ft_acc_no: "",
    reinvest: "Z",
    amount: "",
    sip_from_date: "",
    sip_end_date: "",
    sip_freq: "",
    sip_amount: "",
    sip_period_day: "",
    input_ref_no: "",
    perpetual_flag: "",
    insurance_enabled: "",
    GOAL_BASED_SIP: "",
    GOAL_TYPE: "",
    GOAL_AMOUNT: "",
    FREEDOM_SIP: "",
    FREEDOM_TARGET_SCHEME: "",
    FREEDOM_TENURE: "",
    FREEDOM_SWP_AMOUNT: "",
    FREEDOM_SCHEME_OPTION: "",
    time_horizon: "",
    instrm_amount: "", //sum of all the scheme amounts
    PayMode: "xxxxxxxxx",
    TransactionData: "",
    fundType: "Various funds through NSE",
    view_amount: "",
  });

  useEffect(() => {
    if (isRecommended) {
      const commonObjects = ProductCode.filter((obj1) => {
    
        let scheme = "";
        if (Data_Kfintech_AMC.includes(obj1.AMC)) {
          scheme = obj1.code.replace(obj1.AMC, "") + "GR";
        } else {
          scheme = obj1.code.replace(obj1.AMC, "");
        }
        return TransactionCount.some((obj2) => scheme === obj2.Scheme);
      });

      const selectedSchemesUpdate = commonObjects.map((ele) => {

        let scheme = "";
        if (Data_Kfintech_AMC.includes(ele.AMC)) {
          scheme = ele.code.replace(ele.AMC, "") + "GR";
        } else {
          scheme = ele.code.replace(ele.AMC, "");
        }

        return {
          id: ele.id,
          code: scheme,
          scheme_code: ele.scheme_code,
          AMC: ele.AMC,
          name: ele.name,
        };
      });

      // setSelectedSchemes(commonObjects);
      setSelectedSchemes(selectedSchemesUpdate);

      if (TransactionCount.length > 0) {
        setIsRecommended(false);
      }
    }
  }, [TransactionCount]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name == "ac_no") {
      const bank = userBank.filter((ele) => ele.ACNUM == value)[0];
      setFormData((prev) => ({
        ...prev,
        rtgs_code: bank?.IFSC,
        billdesk_bank: bank?.BANK_CODE,
        bank: bank?.BANK_CODE,
        ac_no: bank?.ACNUM,
        ifsc_code: bank?.IFSC,
      }));
    } else if (name == "time_horizon") {
      setIsRecommended(true);

      RecommendationLogic(
        storeState.ACTIVEINVETOR.type,
        value,
        formData.instrm_amount,
        setTransactionCount
      );

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        trans_count: TransactionCount.length,
        TransactionData: TransactionCount,
      }));
    } else if (name == "view_amount") {
      const numValue = parseNumber(value).toString();
      setIsRecommended(true);

      RecommendationLogic(
        storeState.ACTIVEINVETOR.type,
        formData.time_horizon,
        numValue,
        setTransactionCount
      );

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        instrm_amount: numValue,
        trans_count: TransactionCount.length,
        TransactionData: TransactionCount,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        trans_count: TransactionCount.length,
        TransactionData: TransactionCount,
      }));
    }
  };

  const toWords = new ToWords({
    localeCode: "en-IN", // Indian locale for Lakhs and Crores
    converterOptions: {
      currency: false, // Not converting to currency, just numbers
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let Amount = 0;

    TransactionCount.map((ele) => {
      Amount += Number(ele.Amount);
    });
    if (Amount !== Number(formData.instrm_amount)) {
      setIsFailure(true);
      setMsg("Sum of Amount should be equal to Invesment Amount");
      setIsLoading(false);
      return;
    } else {
      setOpen(true);
      if (formData.payment_mode == "OL" || formData.payment_mode == "UPI") {
        if (
          BankNotOnlinepayment.includes(formData.billdesk_bank) &&
          formData.payment_mode == "OL"
        ) {
          setMsg(
            `Online Payment Method not available for ${
              BankName[formData.billdesk_bank]
            } bank `
          );
          setIsFailure(true);
          return;
        }
        setModelName("OL");
        const Data = {
          ...formData,
          trans_count: TransactionCount.length,
          TransactionData: TransactionCount,
        };
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/create-order`,
            Data,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          )
          .then((res) => {
            const { data } = res;
            if (!data.succ) {
              setIsLoading(false);
              setMsg(data.message);
              setIsFailure(true);
              return;
            }
            setIsSuccess(true);
            setIsLoading(false);
            setMsg(
              `Order submitted successfully for Rs ${formData.instrm_amount}`
            );
            setTimeout(() => {
              navigate(
                `/dashboardAdmin/investment/nse/details/${storeState.ACTIVEINVETOR.folio.Folio}`
              );
            }, 3000);
          })
          .catch(({ response }) => {
            setIsLoading(false);
            const { data } = response;
            //setValidationErrors(data.validationErrors);
          });
      } else if (formData.payment_mode == "M") {
        setModelName(formData.payment_mode);
        // if (BankNotOnlinepayment.includes(formData.billdesk_bank) && formData.payment_mode == "M") {
        //     setMsg(`Online Payment Method not available for ${BankName[formData.billdesk_bank]} bank `)
        //     setIsFailure(true);
        //     return;
        // }
        if (formData.umrn == "") {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/nse/umrn`,
              {
                iin: storeState.ACTIVEINVETOR.folio.Folio,
                folio_id: storeState.ACTIVEINVETOR.folio._id,
              },
              {
                headers: { Authorization: `Bearer ${accessToken}` },
              }
            )
            .then((response) => {
              const { data } = response;
              console.log(data);
              if (!data.succ) {
                setIsLoading(false);
                setMsg(data.message);
                setIsFailure(true);
                return;
              }
              const Data = {
                ...formData,
                umrn: data.umrn,
                trans_count: TransactionCount.length,
                TransactionData: TransactionCount,
              };
              axios
                .post(
                  `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/create-order`,
                  Data,
                  {
                    headers: { Authorization: `Bearer ${accessToken}` },
                  }
                )
                .then((res) => {
                  const resData = res.data;
                  if (!resData.succ) {
                    setIsLoading(false);
                    setMsg(resData.message);
                    setIsFailure(true);
                    return;
                  }
                  setIsLoading(false);
                  setIsSuccess(true);
                  setMsg(
                    `Order submitted successfully for Rs ${formData.instrm_amount}`
                  );
                  setTimeout(() => {
                    navigate(
                      `/dashboardAdmin/investment/nse/details/${storeState.ACTIVEINVETOR.folio.Folio}`
                    );
                  }, 3000);
                })
                .catch(({ response }) => {
                  setIsLoading(false);
                  setIsFailure(true);
                  const { data } = response;
                  setMsg(data?.message);
                  //setValidationErrors(data.validationErrors);
                });
            })
            .catch((error) => {
              setIsLoading(false);
              setIsFailure(true);
              const { data } = error;
              setMsg(data.message);
            });
        } else {
          const Data = {
            ...formData,
            trans_count: TransactionCount.length,
            TransactionData: TransactionCount,
          };
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/create-order`,
              Data,
              {
                headers: { Authorization: `Bearer ${accessToken}` },
              }
            )
            .then((res) => {
              const resData = res.data;
              if (!resData.succ) {
                setIsLoading(false);
                setMsg(resData.message);
                setIsFailure(true);
                return;
              }
              setIsLoading(false);
              setIsSuccess(true);
              setMsg(
                `Order submitted successfully for Rs ${formData.instrm_amount}`
              );
              setTimeout(() => {
                navigate(
                  `/dashboardAdmin/investment/nse/details/${storeState.ACTIVEINVETOR.folio.Folio}`
                );
              }, 3000);
            })
            .catch(({ response }) => {
              setIsLoading(false);
              setIsFailure(true);
              const { data } = response;
              setMsg(data?.message);
              //setValidationErrors(data.validationErrors);
            });
        }
      } else if (formData.payment_mode == "TR") {
        setModelName("TR");
        setIsLoading(false);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setIsFailure(false);
  };

  const HandlerRemoveScheme = (event) => {
    const data = TransactionCount.filter((ele) => ele.Scheme !== event.Scheme);
    setTransactionCount(data);
    setScheme("");
    setSchemes([]);
  };

  const HandleAddOtherFunds = () => {
    setScheme("");
    setSchemes([]);

    const data = {
      Funds: "",
      Scheme: "",
      Amount: "",
      folio: "",
      view_amount: "",
    };

    setTransactionCount((prev) => [...prev, data]);
  };

  const AddNewScheme = (value: any, id: number) => {
    if (value) {
      const newData = [...TransactionCount];

      newData[id] = {
        ...newData[id],
        ["Scheme"]: searchSchemes.filter((ele) => ele.name === value)[0]
          .Product_code,
        ["Funds"]: searchSchemes.filter((ele) => ele.name === value)[0].AMC,
      };

      const combinedArray = [
        ...selectedSchemes,
        {
          name: value,
          code: searchSchemes.filter((ele) => ele.name === value)[0]
            .Product_code,
          AMC: searchSchemes.filter((ele) => ele.name === value)[0].AMC,
        },
      ];

      setSelectedSchemes(combinedArray);

      setTransactionCount(newData);
    }
  };

  const convertAmountToWord = (amount, id) => {
    amount = amount === "" ? 0 : parseFloat(amount.replace(/,/g, ""));

    setActiveRow(id);
    if (amount) {
      setAmountInWord(toWords.convert(amount));
    }
  };

  const HandleChangeTransactionCount = (event, id) => {
    event.preventDefault();
    const { name, value } = event.target;

    const newData = [...TransactionCount];

    if (name === "Fund") {
      setSelectedFund(value);

      axios
        .get(
          `${process.env.REACT_APP_BACKEND_HOST}v1/funds/schemes/search/amc?amc=${value}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((res) => {
          setSearchSchemes(res.data.schemes);
          setSchemes(res.data.schemes);
        })
        .catch((error) => {
          console.log("error from get schemes of selected schemes", error);
          setMsg(error?.message);
        });
    } else if (name === "Scheme") {
      // const

      newData[id] = {
        ...newData[id],
        [name]: searchSchemes.filter((ele) => ele.id === value)[0].code,
        ["Funds"]: searchSchemes.filter((ele) => ele.id === value)[0].AMC,
      };
    } else if (name === "view_amount") {
      newData[id] = {
        ...newData[id],
        [name]: value,
        ["Amount"]: parseNumber(value),
      };

      convertAmountToWord(value, id);
    }

    setTransactionCount(newData);
  };

  const handleSchemeChange = (newInputValue: string) => {
    setScheme(newInputValue);

    const existingTransactionSchemes = TransactionCount.map(
      (tx: any) => tx.Scheme
    );

    let schemes = searchSchemes.filter(
      (s: any) => !existingTransactionSchemes.includes(s.Product_code)
    );

    schemes = schemes.filter((s) =>
      s.name.toLowerCase().includes(newInputValue.toLowerCase())
    );

    setSchemes(schemes);
  };

  const OnAmountFocus = (event, id: number) => {
    const { value } = event.target;

    convertAmountToWord(value, id);
  };

  const handleBlurAmountField = (id: number) => {
    if (activeRow === id) {
      setAmountInWord(null);
      setActiveRow(null);
    }
  };

  return (
    <>
      {["Sunday", "Saturday"].includes(Name_Days) ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <Typography
            sx={{
              background: "yellow",
              padding: "4px",
              borderRadius: "10px",
              textAlign: "center",
              width: { xs: "90%", sm: "600px" },
            }}
          >{`Kindly note that today is a holiday as exchanges are closed on ${Name_Days}. Also, ${dateConverter(
            isholiday?.date
          )} is a holiday on account of ${isholiday?.Desc}.`}</Typography>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <Typography
            sx={{
              background: "yellow",
              padding: "4px",
              borderRadius: "10px",
              textAlign: "center",
              width: { xs: "90%", sm: "600px" },
            }}
          >{`Kindly note that ${dateConverter(
            isholiday?.date
          )} is a holiday on account of ${isholiday?.Desc}.`}</Typography>
        </div>
      )}
      <div style={{ margin: "2rem" }}>
        <Card
          sx={{ maxWidth: 700, margin: "0 auto" }}
          style={{ border: "1px solid black" }}
        >
          <CardContent>
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <NumericFormat
                type="text"
                label="Amount"
                name="view_amount"
                value={formData.view_amount}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!validationErrors.view_amount}
                helperText={validationErrors.view_amount}
                required
                thousandsGroupStyle="lakh"
                thousandSeparator=","
                customInput={TextField}
                decimalScale={2}
              />
              <TextField
                label="Time Horizon"
                name="time_horizon"
                onChange={handleChange}
                value={formData.time_horizon}
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!validationErrors.product_code}
                helperText={validationErrors.product_code}
                required
                select
              >
                {TimeHorizon.map((each, idx) => (
                  <MenuItem key={idx} value={each}>
                    {each}
                  </MenuItem>
                ))}
              </TextField>
              {TransactionCount.length > 0 && (
                <>
                  <Typography sx={{ fontWeight: 600 }}>
                    Our Recommendation
                  </Typography>
                  {TransactionCount.map((ele, idx) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        {ele.Scheme !== "" && (
                          <TextField
                            label="Fund"
                            name="Fund"
                            onChange={(e) =>
                              HandleChangeTransactionCount(e, idx)
                            }
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.Scheme}
                            helperText={validationErrors.Scheme}
                            required
                            value={ele.Scheme}
                            select
                            disabled
                          >
                            <MenuItem value={ele.Scheme} key={ele.id}>
                              {
                                FundData.filter(
                                  (element) => element.AMC == ele.Funds
                                )[0]?.name
                              }
                            </MenuItem>
                          </TextField>
                        )}
                        {ele.Scheme !== "" && (
                          <TextField
                            label="Scheme"
                            name="Scheme"
                            onChange={(e) =>
                              HandleChangeTransactionCount(e, idx)
                            }
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.Scheme}
                            helperText={validationErrors.Scheme}
                            required
                            value={ele.Scheme}
                            select
                            disabled
                          >
                            <MenuItem value={ele.Scheme} key={ele.id}>
                              {
                                selectedSchemes.filter(
                                  (element) =>
                                    element.code == ele.Scheme &&
                                    element.AMC == ele.Funds
                                )[0]?.name
                              }
                            </MenuItem>
                          </TextField>
                        )}
                        {ele.Scheme == "" && (
                          <TextField
                            label="Fund"
                            name="Fund"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            onChange={(e) =>
                              HandleChangeTransactionCount(e, idx)
                            }
                            error={!!validationErrors.Scheme}
                            helperText={validationErrors.Scheme}
                            required
                            select
                          >
                            {FundData.map((e) => (
                              <MenuItem value={e.AMC} key={e.AMC}>
                                {e.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                        {ele.Scheme == "" && (
                          <Autocomplete
                            fullWidth
                            freeSolo
                            options={Schemes.map((s: any) => s.name)}
                            value={scheme}
                            onChange={(e, value) => AddNewScheme(value, idx)}
                            onInputChange={(event, newInputValue) =>
                              handleSchemeChange(newInputValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Scheme"
                                name="Scheme"
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        )}
                        {/* <TextField
                                        label="Amount"
                                        name="Amount"
                                        value={ele.Amount}
                                        onChange={(e) => HandleChangeTransactionCount(e, idx)}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        error={!!validationErrors.Amount}
                                        helperText={validationErrors.Amount}
                                        required
                                        sx={{ width: "50%" }}
                                    /> */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column", // This stacks the NumericFormat and Typography vertically
                            width: "50%", // Adjust width as needed
                          }}
                        >
                          <NumericFormat
                            label="Amount"
                            name="view_amount"
                            value={ele.view_amount}
                            onChange={(e) =>
                              HandleChangeTransactionCount(e, idx)
                            }
                            onFocus={(e) => OnAmountFocus(e, idx)}
                            onBlur={() => handleBlurAmountField(idx)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.Amount}
                            helperText={validationErrors.Amount}
                            required
                            customInput={TextField}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                            decimalScale={2}
                          />
                          {activeRow === idx &&
                            parseInt(ele.view_amount) > 0 && (
                              <Typography
                                variant="body2"
                                sx={{
                                  marginTop: "8px",
                                  marginLeft: "14px",
                                  fontSize: "0.875rem",
                                }}
                              >
                                {amountInWord}
                                {/* Convert number to words */}
                              </Typography>
                            )}
                        </Box>
                        <Clear
                          onClick={() => HandlerRemoveScheme(ele)}
                          sx={{
                            border: "1px solid #c4c4c4",
                            height: "25px",
                            width: "25px",
                            borderRadius: "5px",
                            color: "red",
                          }}
                        />
                      </Box>
                    );
                  })}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isLoading || TransactionCount.length === 10}
                      onClick={HandleAddOtherFunds}

                      //sx={{ marginTop: 2, marginLeft: { xs: "25%", sm: "35%" }, width: { xs: "40%", sm: "30%" } }}
                    >
                      ADD OTHER FUNDS
                    </Button>
                  </Box>
                </>
              )}
              <TextField
                label="Bank"
                name="ac_no"
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!validationErrors.ac_no}
                helperText={validationErrors.ac_no}
                required
                select
                defaultValue={formData.ac_no}
              >
                {userBank?.map((ele, indx) => {
                  return (
                    <MenuItem
                      key={indx}
                      value={ele.ACNUM}
                    >{`${ele.BANK_NAME} (${ele.ACNUM})`}</MenuItem>
                  );
                })}
              </TextField>
              <TextField
                label="Payment Mode"
                name="payment_mode"
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!validationErrors.payment_mode}
                helperText={validationErrors.payment_mode}
                required
                select
              >
                {PaymentMode.filter((ele) =>
                  storeState?.ACTIVEINVETOR?.type != 1
                    ? ele.code !== "UPI"
                    : ele.code
                ).map((each, idx) => (
                  <MenuItem key={idx} value={each.code}>
                    {each.name}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
                fullWidth
                sx={{ marginTop: 2, width: "30%", marginLeft: "35%" }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "INVEST"
                )}
              </Button>
            </form>
          </CardContent>
          <Snackbar
            open={isSuccess}
            autoHideDuration={3000}
            onClose={() => setIsSuccess(false)}
            sx={{ marginBottom: 2 }}
          >
            <Alert severity="success">{msg}</Alert>
          </Snackbar>
          <Snackbar
            open={isFailure}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            sx={{ marginBottom: 2 }}
          >
            <Alert severity="error">{msg}</Alert>
          </Snackbar>
        </Card>
        {modelName == "TR" && (
          <div>
            <RTGSPayment
              state={state}
              setValidationErrors={setValidationErrors}
              validationErrors={validationErrors}
              isLoading={isLoading}
              setFormData={setFormData}
              formData={formData}
              open={open}
              setOpen={setOpen}
              setMsg={setMsg}
              setIsFailure={setIsFailure}
              setIsLoading={setIsLoading}
              setIsSuccess={setIsSuccess}
              accessToken={accessToken}
            />
          </div>
        )}
        {modelName == "OL" && isSuccess && (
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -75%)",
                  bgcolor: "background.paper",
                  borderRadius: "10px",
                  minWidth: "40vw",
                  boxShadow: 24,
                  p: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" m={2}>
                  Online Payment Link
                </Typography>
                <Divider sx={{ mb: 2, color: "blue" }} />
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <Typography id="modal-modal-description">
                    Kindly check your email for the payment link
                  </Typography>
                )}
              </Box>
            </Modal>
          </div>
        )}
        {/* {modelName == "M" && <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -75%)',
                        bgcolor: 'background.paper',
                        borderRadius: "10px",
                        minWidth: "40vw",
                        boxShadow: 24,
                        p: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <Typography variant='h5' >E Mandate</Typography>
                        <Divider />
                        <Typography id="modal-modal-description">
                            Transaction Completed
                        </Typography>
                        <Divider />
                        <TextField
                            label="Enter OTP"
                            name="otp"
                            onChange={(e) => { setOTP(e.target.value) }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={!!validationErrors.otp}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                            fullWidth
                            sx={{ marginTop: 2, width: "150px", height: "40px" }}
                            onClick={handleSubmitPOPUP}
                        >
                            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'INVEST'}
                        </Button>
                    </Box>
                </Modal>
            </div>} */}
      </div>
    </>
  );
};

export default CreateMultipleOrdersNSE;
